import path from 'path';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { holidays as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';
import BackgroundImage from 'gatsby-background-image';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    Link,
    PersonalLoanForm,
    ScrollMarker,
    SEO,
    TrustIcons,
    Variants
} from '@brandedholdings/react-components';
import SectionHero from '../components/landing-pages/sections/Hero';
import SectionCTA from '../components/landing-pages/sections/CTA';
import SectionFAQs from '../components/landing-pages/sections/FAQs';
import SectionRequirements from '../components/landing-pages/sections/Requirements';
import IconCash from '../images/icons/icon-cash.svg';
import IconIdea from '../images/icons/icon-idea.svg';
import IconLock from '../images/icons/icon-lock.svg';
import IconMagicStick from '../images/icons/icon-magic-stick.svg';
import IconSpeed from '../images/icons/icon-speed.svg';

import LogoCnn from '../images/logos/logo-cnn.svg';
import LogoFoxNews from '../images/logos/logo-fox-news.svg';
import LogoMsnbc from '../images/logos/logo-msnbc.svg';
import LogoVh1 from '../images/logos/logo-vh1.svg';
import LogoHallmarkChannel from '../images/logos/logo-hallmark-channel.svg';

import LogoAsSeenOnTV from '../images/logos/tv.svg';
import LogoAsHeardOnRadio from '../images/logos/as-heard-on-radio.svg';

import "../../stylesheets/holidays.scss";

class Holidays extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            variant: 'default',
            showRLA: true,
            finalLoanAmounts: {
                min: 0,
                max: 0
            },
        };
        this.setRLA = this.setRLA.bind(this);
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    setRLA(e,obj) {
        this.setState({
            finalLoanAmounts: {
                ...obj
            }
        }, () => {
            this.setupApplicationFocus(e);
        });
    }

    setupApplicationFocus(e) {
        if (this._personalLoanForm !== null) {
            e.preventDefault();
            ScrollMarker.scrollToMarker('heroStart');
            this._personalLoanForm.getWrappedInstance().focusApplication();
        }
    }

    componentDidMount() {
        const match = document.location.href.toLowerCase().match(/(([?&#])startnow=true|([?&#])promoid|([?&#])promo_id|([?&#])utm_source=revpie)/i);

        if (match instanceof Array) {
            this.setState({ variant: 'x' });
        }
    }

    render() {
        const page = this.props;
        this._personalLoanForm = null;
        const {
            finalLoanAmounts,
            showRLA,
        } = this.state;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);
        const variants = new Variants()
        const enableSMS = variants.active.sms_optin && (variants.active.sms_optin === 'test');
        const enableTitleLoan = variants.active.title_loan && (variants.active.title_loan === 'test');
        const enableDebtQuestions = variants.active.debt_questions && (variants.active.debt_questions === 'test');
        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);


        const maxLoanValue = 5000;
        const terms = "/terms";
        const privacy = "/privacy";
        const electronicDisclosure = "/terms/#electronic-disclosure";
        const creditDisclosure = "/terms/#credit-disclosure";


        return (
            <PageWrapper onClick={this.setupApplicationFocus.bind(this)} landingPage="true">
                <SEO post={post} />
                <div className="hero__funnel">
                    <BackgroundImage
                        className="background__gatsby hero hero__alternative background__graphic"
                        fluid={pageImages.hero.image.fluid}
                    >

                    </BackgroundImage>
                    <BackgroundImage
                        className="hero background background__spokesperson background__spokesperson--holidays text-shadow__blue headlines__bold hide-on-init"
                        fluid={pageImages.holidayspokesperson.image.fluid}
                        style={{ backgroundColor: 'transparent' }}
                        durationFadeIn={0}
                        loading="eager"
                    >
                        <div className="layout">
                            <div className="hero--message">
                                <SectionHero
                                    headline="Looking for quick funds for the holidays?"
                                    setRLA={this.setRLA}
                                />
                                <div className="form__footer">
                                    <TrustIcons icons={['mcafee', 'secured']} siteMeta={siteMeta} height={26} />
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                    <div className="background form--hidden">
                        <div className="layout">
                            <div className="form split-form">
                                <PersonalLoanForm
                                    minLoanValue={1000}
                                    maxLoanValue={maxLoanValue}
                                    modalDisclaimerText={siteMeta.modalDisclaimer}
                                    enableOverlay={true}
                                    ref={(personalLoanForm) => { this._personalLoanForm = personalLoanForm; }}
                                    trustIcons={['mcafee', 'secured']}
                                    urlTerms={terms}
                                    urlPrivacy={privacy}
                                    urlElectronicDisclosure={electronicDisclosure}
                                    urlCreditDisclosure={creditDisclosure}
                                    checklistItem1={siteMeta.funnel.checklist.item1}
                                    checklistItem2={siteMeta.funnel.checklist.item2}
                                    checklistItem3={siteMeta.funnel.checklist.item3}
                                    depositInfoSubheading={siteMeta.funnel.depositInfo.subheading}
                                    depositInfoExpanderItem1Answer={siteMeta.funnel.depositInfo.expanderItem1.answer}
                                    depositInfoExpanderItem2Answer={siteMeta.funnel.depositInfo.expanderItem2.answer}
                                    depositInfoExpanderItem3Answer={siteMeta.funnel.depositInfo.expanderItem3.answer}
                                    depositInfoExpanderItem4Answer={siteMeta.funnel.depositInfo.expanderItem4.answer}
                                    initialLegalCertification={(
                                        <small>By submitting my information, I certify that I am a U.S. resident, at least 18 years of age, agree to the <Link to={privacy} tabIndex="-1" target="_blank">Privacy Policy</Link> and <Link to={terms} tabIndex="-1" target="blank">Terms of Web Site Use</Link>, and that I consent to receive offers from you and from third parties.</small>
                                    )}
                                    processingHeading="Lenders are reviewing your information."
                                    processingSubheading="If a lender is ready to work with you, you’ll be redirected to their&nbsp;website."
                                    secondChanceMessage1={siteMeta.secondChance.message.line1}
                                    secondChanceMessage2={siteMeta.secondChance.message.line2}
                                    resumeSubheading={(<span>When You Need It</span>)}
                                    disableCreditRatingStep
                                    enableProcessingWithOffer
                                    enableGoogleStreet
                                    showRLA={showRLA}
                                    requestedLoanAmountMin={finalLoanAmounts.min === 0 ? '' : finalLoanAmounts.min.toString()}
                                    requestedLoanAmountMax={finalLoanAmounts.max === 0 ? '' : finalLoanAmounts.max.toString()}
                                    enableTitleLoan
                                    enableDebtQuestions
                                />
                            </div>
                            <div className="form__footer">
                                <TrustIcons icons={['mcafee', 'secured']} siteMeta={siteMeta} height={26} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="badges__as-seen">
                    <LogoAsSeenOnTV />
                    <LogoAsHeardOnRadio />
                </div>
                <div className="asSeenOn">
                    <LogoCnn />
                    <LogoFoxNews />
                    <LogoMsnbc />
                    <LogoVh1 />
                    <LogoHallmarkChannel />
                </div>
                <div id="about-us" className="background background__white headlines__bold">
                    <div className="layout">
                        <div className="layout-content">
                            <div className="layout-content__header center">
                                <h2 className="heading__main">
                                    Get the money you need, when you need it.
                            </h2>
                            </div>
                            <div className="layout-content__body text-muted">
                                <p>{siteMeta.nameShort} is an intelligent marketplace designed to help you find a personal loan lender now. With {siteMeta.nameShort} you can find hassle-free financial assistance from reputable lenders, fast. Get the funds you need from a lender fast &mdash; sometimes by the next business day!*</p>
                                <br />
                            </div>
                            <div className="layout-content__body">
                                <div className="layout--3-column-split">
                                    <div className="layout-content center border border__radius">
                                        <div className="icon-group">
                                            <div className="icon-group--icon__circle">
                                                <IconMagicStick className="icon-group--icon icon__color" />
                                            </div>
                                            <div className="icon-group--text">
                                                <h5>
                                                    Easy Request Process
                                            </h5>
                                                <p className="text-muted">
                                                    We offer you simple, quick and secure access to lenders
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="spacer" />
                                    <div className="layout-content center border border__radius">
                                        <div className="icon-group">
                                            <div className="icon-group--icon__circle">
                                                <IconSpeed className="icon-group--icon icon__color" />
                                            </div>
                                            <div className="icon-group--text">
                                                <h5>
                                                    Fast Decisions
                                            </h5>
                                                <p className="text-muted">
                                                    You could get your funds from a lender as early as the next business day*
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="spacer" />
                                    <div className="layout-content center border border__radius">
                                        <div className="icon-group">
                                            <div className="icon-group--icon__circle">
                                                <IconLock className="icon-group--icon icon__color" />
                                            </div>
                                            <div className="icon-group--text">
                                                <h5>
                                                    Safe &amp; Secure
                                            </h5>
                                                <p className="text-muted">
                                                    We use the latest SSL technology to keep your information private and secure.
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BackgroundImage
                    className="background__laptop"
                    fluid={pageImages.christmastree.image.fluid}
                    style={{ backgroundSize: '0 0', backgroundPosition: 'center left' }}
                    id="howitworks"
                >
                    <div className="layout">
                        <div className="layout-content">
                            <div className="layout-content__header">
                                <h2 className="heading__main">
                                    How it works
                            </h2>
                            </div>
                            <div className="layout-content__body">
                                <h4 className="font-weight-300">
                                    <IconIdea className="icon__color icon__inline" />
                                Submit your request in just a few minutes
                            </h4>
                                <p>
                                    The entire process is safe, secure and completely online. No one to deal with in person, {siteMeta.nameShort} does not charge you any fees to use our service.
                            </p>
                                <br />
                                <h4 className="font-weight-300">
                                    <IconCash className="icon__color icon__inline" />
                                Get the money you need &mdash; <em>fast!</em>
                                </h4>
                                <p>
                                    Once you&rsquo;ve completed the form, your information is reviewed instantaneously by lenders to determine if they would like to work with you. If a lender decides they would like to work with you, you will be redirected to their website where you complete the lender&rsquo;s forms, review the loan terms, and if agreeable to you, accept the loan to collect your funds as early as the next business day.*
                            </p>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
                <BackgroundImage
                    className="background background__graphic text-shadow__blue headlines__bold"
                    fluid={pageImages.hero.image.fluid}
                >
                    <SectionCTA />
                </BackgroundImage>
                <div className="background background__white headlines__bold">
                    <div className="layout">
                        <div className="layout-content">
                            <div className="layout-content__header center">
                                <h2 className="heading__main">
                                    What you&rsquo;ll need
                            </h2>
                            </div>
                            <div className="layout-content__body">
                                <SectionRequirements />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="questions" className="background background__extra-light-gray headlines__bold alternative">
                    <div className="layout layout--narrow">
                        <div className="layout-content__header">
                            <h2 className="heading__main center">
                                Common Questions
                        </h2>
                        </div>
                        <div className="layout-content__body">
                            <SectionFAQs />
                        </div>
                    </div>
                </div>
                <BackgroundImage
                    className="background background__graphic text-shadow__blue headlines__bold"
                    fluid={pageImages.hero.image.fluid}
                >
                    <SectionCTA howMuch />
                </BackgroundImage>
            </PageWrapper>
        );
    }
};

Holidays.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object
};

export default Holidays;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;
